import './App.css';
import description from './content/description.json';
import experiences from './content/experiences.json';
import formations from './content/formations.json';
// import langues from './content/langues.json';
import competences from './content/competences.json';
// import loisirs from './content/loisirs.json';

function App() {
  return (
    <div className="App">
      <div className='CV-header'>
        <div className='CV-header-col flex2'>
          <h1>
            {`> ${description.name}`}
          </h1>
          <h5>
            Date de naissance : {description.birthdate}
          </h5>
          <h5>
            <div>Adresse : </div>
            {description?.address?.address && <div>{description.address.address}</div>}
            <div>{description.address.zip} {description.address.city}</div>
          </h5>
          {description.phone && 
            <h5>
              Mobile : {description.phone}
            </h5>
          }
          {description.mail &&
            <h5>
              Email : <a href={`mailto:${description.mail}`}>M'écrire</a>
            </h5>
          }
        </div>
        <div className='CV-header-col flex1'>
          <img src={description.avatar} alt="avatar" className='CV-avatar'/>
        </div>
        <div className='CV-header-col flex2 align-center'>
          <h2>
            {`<${description.title}/>`}
          </h2>
          <h4>
            {description.subtitle}
          </h4>
        </div>

      </div>

      <div className='CV-body'>
        <div className='experiences-container'>
          <h3>Expériences professionnelles</h3>

          {experiences.map((experience, index) => 
            <div className='item-container' key={'xp' + index}>
              <div className='flex2 item-col1'>
                <div className='item-date'>
                  {experience.fromDate && experience.toDate ? 
                  `${experience.fromDate} - ${experience.toDate}` :
                  experience.date ? 
                    `${experience.date}` :
                    ``
                  }
                </div>
                {experience.duration &&
                  <div className='item-duration'>
                    ({experience.duration})
                  </div>
                }
                <div className='item-tags-container'>
                  {(experience.tags || []).map((tag, tagIndex) => 
                    <div className='experience-tag' key={'tag' + tagIndex}>
                      {tag}
                    </div>
                  )}
                </div>
              </div>

              <div className='flex5 item-col2'>
                <div className='item-title'>
                  {`> ${experience.title}`}
                </div>
                <div className='item-intro'>
                  <div className='item-intro-first-line'>
                    <div className='item-company'>@ {experience.company}</div>
                    <div className='item-place'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="place-icon" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                      </svg>
                      {experience.place}
                    </div>
                  </div>

                  <div className='item-company-desc'>{experience.companyDesc}</div>
                </div>
                
                <div className='item-description'>
                  {experience.description.description}

                  <div className='item-description-features-container'>
                    {(experience.description.features || []).map((feature, featIndex) => 
                      <div className='item-description-feature' key={'feat' + featIndex}>
                        {`> ${feature}`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
            </div>
          )}
        </div>
        
        <div className='competences-container'>
          <h3>Compétences</h3>

          <h5>
            Techniques
          </h5>
          <div className='competences'>
            {competences.hardskills.join(' | ')}
          </div>

          <div className='small-margin-top'>
            <h5>
              Diverses
            </h5>
            <div className='competences'>
              {competences.softskills.join(' | ')}
            </div>
          </div>
        </div>

        <div className='formations-container'>
          <h3>Diplômes</h3>

          {formations.map((formation, index) => 
            <div className='item-container' key={'form' + index}>
              <div className='flex2 item-col1'>
                <div className='item-date'>
                  {formation.fromDate && formation.toDate ? 
                  `${formation.fromDate} - ${formation.toDate}` :
                  formation.date ? 
                    `${formation.date}` :
                    ``
                  }
                </div>
                {formation.duration &&
                  <div className='item-duration'>
                    ({formation.duration})
                  </div>
                }
                <div className='item-tags-container'>
                  {(formation.tags || []).map((tag, tagIndex) => 
                    <div className='experience-tag' key={'tag' + tagIndex}>
                      {tag}
                    </div>
                  )}
                </div>
              </div>

              <div className='flex5 item-col2'>
                <div className='item-title'>{formation.title}</div>
                <div className='item-intro'>
                  <div className='item-intro-first-line'>
                    <div className='item-company'>@ {formation.company}</div>
                    <div className='item-place'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16" className='place-icon'>
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                      </svg>
                      {formation.place}
                    </div>
                  </div>

                  <div className='item-company-desc'>{formation.companyDesc}</div>
                </div>
                
                {formation.description && 
                  <div className='item-description'>
                    {formation.description.description}

                    <div className='item-description-features-container'>
                      {(formation.description.features || []).map((feature, featIndex) => 
                        <div className='item-description-feature' key={'feat' + featIndex}>
                          {`> ${feature}`}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
              
            </div>
          )}
        </div>

        {/* <div className='langues-container'>
          <h3>Langues</h3>

          {langues.map((langue, index) => 
            <div className='langue-container' key={'langue' + index}>
              <div className='item-title'>{langue.title}</div>
              <div className='item-subtitle'>{langue.subtitle}</div>
            </div>
          )}
        </div>

        <div className='loisirs-container'>
          <h3>Loisirs</h3>
          {loisirs.map((loisir, index) => 
            <div className='loisir-container' key={'loisir' + index}>
              <div className='item-title'>{loisir.title}</div>
              <div className='item-subtitle'>{loisir.subtitle}</div>
            </div>
          )}
        </div> */}
      </div> 
    </div>
  );
}

export default App;
